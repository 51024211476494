<template>
  <div>
    <!-- Top Buttons -->
    <b-row>
      <b-col class="mb-2">
        <b-button variant="danger" v-b-modal.modal-1>+ Add Postal</b-button>
        <b-button variant="danger" class="mx-1" v-b-modal.PostalListModal>Postal List</b-button>
      </b-col>
    </b-row>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search all field"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->

    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <template
          v-if="
            props.column.field === 'zone' ||
            props.column.field === 'actions' ||
            props.column.field === 'postcode_range'
          "
        >
          <span v-if="props.column.field === 'zone'">
            <b-button block variant="danger"> {{ props.row.zone }}</b-button>
          </span>
          <span v-if="props.column.field === 'postcode_range'">
            {{ props.row.postal_start }} - {{ props.row.postal_end }}
          </span>
          <span v-if="props.column.field === 'document'">
            <b-button
              :to="{ name: 'driver', params: { document: props.row.document } }"
              block
              variant="danger"
              size="sm"
            >
              Upload Driving License
            </b-button>
          </span>

          <span v-if="props.column.field === 'status'">
            <b-button block variant="danger" size="sm"> Active </b-button>
          </span>

          <span v-if="props.column.field === 'actions'">
                <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="v_edit(props.row)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDelete(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
 
 
            <!-- <b-form-select v-model="selected" :options="options" /> -->
            <!--<b-select
      v-model="selectedOption"
      required
      @change="onOptionChanged">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value">
        {{ option.label }}
      </option>
    </b-select>-->
          </span>
        </template>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!--Back to Dashboard-->
    <b-col cols="12 text-center">
      <b-button variant="danger" :to="{ name: 'dashboard' }" class="mr-1"
        >Back to Dashboard</b-button
      >
    </b-col>

    <!--Modal Add Postal-->
    <b-modal
    ref="modalADD"
      id="modal-1"
      size="xl"
      ok-only
      ok-title="Submit"
      hide-footer
      centered
    >
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col cols="12">
            <div class="card">
              <div class="card-body">
                <b-row>
                  <b-col cols="12">
                    <h2 class="mb-2" style="color: red; font-weight: bold">
                      ADD POSTAL
                    </h2>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Zone" label-for="zone">
                      <validation-provider
                        #default="{ errors }"
                        name="Zone"
                        rules="required"
                      >
                        <b-form-input
                          id="zone"
                          v-model="form.zone"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Zone"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Area">
                      <validation-provider
                        #default="{ errors }"
                        name="Area"
                        rules="required"
                      >
                        <b-form-input
                          id="area"
                          v-model="form.area"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Area"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="State">
                      <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <b-form-input
                          id="state"
                          v-model="form.state"
                          :state="errors.length > 0 ? false : null"
                          placeholder="State"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Postal Start">
                      <validation-provider
                        #default="{ errors }"
                        name="Postal Start"
                        rules="required"
                      >
                        <b-form-input
                          id="postal_start"
                          v-model="form.postal_start"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Postal Start"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Postal End">
                      <validation-provider
                        #default="{ errors }"
                        name="Postal End"
                        rules="required"
                      >
                        <b-form-input
                          id="postal_end"
                          v-model="form.postal_end"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Postal End"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
            <b-col class="mb-2 text-center">
              <b-button variant="danger" @click.prevent="validationForm">
                Submit</b-button
              >
            </b-col>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <!--Modal Edit Postal-->
    <b-modal ref="modal-2" size="xl" hide-footer centered>
      <validation-observer ref="editRules">
        <b-row>
          <b-col cols="12">
            <div class="card">
              <div class="card-body">
                <b-row>
                  <b-col cols="12">
                    <h2 class="mb-2" style="color: red; font-weight: bold">
                      EDIT POSTAL
                    </h2>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Zone" label-for="zone">
                      <validation-provider
                        #default="{ errors }"
                        name="Zone"
                        rules="required"
                      >
                        <b-form-input
                          id="zone"
                          v-model="form2.zone"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Zone"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Area">
                      <validation-provider
                        #default="{ errors }"
                        name="Area"
                        rules="required"
                      >
                        <b-form-input
                          id="area"
                          v-model="form2.area"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Area"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="State">
                      <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <b-form-input
                          id="state"
                          v-model="form2.state"
                          :state="errors.length > 0 ? false : null"
                          placeholder="State"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Postal Start">
                      <validation-provider
                        #default="{ errors }"
                        name="Postal Start"
                        rules="required"
                      >
                        <b-form-input
                          id="postal_start"
                          v-model="form2.postal_start"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Postal Start"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Postal End">
                      <validation-provider
                        #default="{ errors }"
                        name="Postal End"
                        rules="required"
                      >
                        <b-form-input
                          id="postal_end"
                          v-model="form2.postal_end"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Postal End"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
            <b-col class="mb-2 text-center">
              <b-button variant="danger" @click.prevent="saveCoverage">
                Submit</b-button
              >
            </b-col>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
        <!--Modal Postal List-->
    <b-modal ref="PostalListModal" size="xl" id="PostalListModal" hide-footer centered>
    <!-- table -->
    <b-col cols="12">
                    <h2 class="mb-2" style="color: red; font-weight: bold">
                     POSTAL LIST
                    </h2>
                  </b-col>
    <vue-good-table
      :columns="columns1"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <template
          v-if="
            props.column.field === 'zone' ||

            props.column.field === 'postcode_range'
          "
        >
     <span v-if="props.column.field === 'zone'">
       {{ props.row.zone }}
          </span>
          <span v-if="props.column.field === 'postcode_range'">
            {{ props.row.postal_start }} - {{ props.row.postal_end }}
          </span>


          <span v-if="props.column.field === 'status'">
            <b-button block variant="danger" size="sm"> Active </b-button>
          </span>

        </template>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "@axios";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      form: {},
      form2: {},
      sending: false,
      selected: null,
      selected1: null,
      options1: [
        { value: null, text: "Select" },
        { value: "a", text: "Perlis" },
        { value: "b", text: "Pulau Pinang" },
        { value: "c", text: "Perak" },
        { value: "d", text: "Pahang" },
        { value: "e", text: "Kelantan" },
        { value: "f", text: "Terengganu" },
        { value: "g", text: "Selangor" },
        { value: "h", text: "Negeri Sembilan" },
        { value: "i", text: "Melaka" },
        { value: "g", text: "Johor" },
        { value: "k", text: "Sabah" },
        { value: "l", text: "Sarawak" },
      ],
      pageLength: 5,
      dir: false,

      columns: [
        {
          label: "Zone",
          field: "zone",
        },
        {
          label: "Area",
          field: "area",
        },
        {
          label: "State",
          field: "state",
        },
        {
          label: "Postcode Range",
          field: "postcode_range",
        },

        {
          label: "Action",
          field: "actions",
        },
      ],
            columns1: [
        {
          label: "Zone",
          field: "zone",
        },
        {
          label: "Area",
          field: "area",
        },
        {
          label: "State",
          field: "state",
        },
        {
          label: "Postcode Range",
          field: "postcode_range",
        },

 
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getCoverage();
  },
  methods: {
    //Add Coverage

    validationForm() {
      const self = this;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          self.show = true;
          const headers = {
            Accept: "application/json",
          };

          const formData = new FormData();
          for (const key in self.form) {
            formData.append(key, self.form[key]);
          }
          axios
            .post(`${this.$appURL}add_coverage`, formData, { headers })
            .catch((err) => {})
            .then((response) => {
              self.show = false;
              // this.sendEmailADD();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: ``,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            });
             this.$refs["modalADD"].hide();
        }
      });
    },

    //Get Coverage
    getCoverage() {
      const self = this;
      const headers = {
        Accept: "application/json",
      };
      axios
        .get(`${this.$appURL}getCoverage`, { headers })
        .catch((err) => {})
        .then((response) => {
          self.rows = response.data.data;
        });
    },

    //Save Changes
    saveCoverage() {
      const self = this;
      const headers = {
        Accept: "application/json",
      };
      this.$refs.editRules.validate().then((success) => {
        if (success) {
          self.sending = true;
          const formData = new FormData();
          for (const key in self.form2) {
            formData.append(key, self.form2[key]);
          }

          axios
            .post(`${this.$appURL}edit_coverage`, formData, { headers })
            .catch((err) => {})
            .then((response) => {
              self.sending = false;
              if (response.data.status) {
                // this.sendEmailEDIT();
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: ``,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });

                this.$refs["modal-2"].hide();
                self.form2 = {};
              }
              self.getCoverage();
            });
        }
      });
    },
    confirmDelete(index) {
      const self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const headers = {
            Accept: "application/json",
          };
          axios
            .post(
              `${this.$appURL}delete_coverage`,
              {
                id: index,
              },
              { headers }
            )
            .catch((err) => {})
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(function () {
                location.reload()
              });
            });
        }
      });
    },
    v_edit(row) {
      this.form2 = { ...row };
      this.$refs["modal-2"].show();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
